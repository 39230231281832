import CTA from '@/atoms/common/CTA';
import { Tabs, NoItemFound } from '@/molecules';
import UserEventSummaryCard from '@/molecules/myEvents/UserEventSummaryCard';

const AddEventsButton = ({ setDisplayAddEventForm, allowAddNewEvent }) => (
  <div>
    <CTA
      {...{
        onClick: () => setDisplayAddEventForm(true),
        disabled: !allowAddNewEvent,
        children: 'Add Events',
        buttonColor: 'text-white px-4 bg-brand-gradient py-2 -mt-2'
      }}
    />
  </div>
);

const UserEventSummaryCardList = ({
  allowAddNewEvent,
  pastUserEventSummaryCardList = false,
  setDisplayAddEventForm,
  userEventSummaryCardList = [],
  ...props
}) => {
  const showAddNewEventIcon = !allowAddNewEvent || pastUserEventSummaryCardList;
  return userEventSummaryCardList?.length > 0 ? (
    userEventSummaryCardList.map((userEventSummaryCard) => (
      <UserEventSummaryCard
        key={userEventSummaryCard.id}
        {...{ props, userEventSummaryCard }}
      />
    ))
  ) : (
    <NoItemFound
      {...{
        title: showAddNewEventIcon
          ? 'No Events Found'
          : 'Click to Add an Event',
        noItemIcon: { alt: 'No Events Found' },
        actionIcon: { alt: 'Add New Event' },
        action: {
          type: 'onClick',
          title: 'Add Event',
          onClick: () => setDisplayAddEventForm(true),
          hide: showAddNewEventIcon
        }
      }}
    />
  );
};

const UserEventNavigatorOrganism = ({
  allowAddNewEvent = false,
  ongoingUserEventSummaryCardList,
  pastUserEventSummaryCardList,
  setDisplayAddEventForm,
  setShowTabTitle
}) => (
  <div className='px-1 md:px-10 relative'>
    <div className='absolute top-2 left-80 float-right hidden md:flex justify-end'>
      <AddEventsButton {...{ setDisplayAddEventForm, allowAddNewEvent }} />
    </div>
    <Tabs
      {...{
        setShowTabTitle,
        className: 'py-1 w-full',
        tabContainerStyle: 'py-1 px-3  w-full',
        tabs: [
          {
            content: (
              <UserEventSummaryCardList
                {...{
                  userEventSummaryCardList: ongoingUserEventSummaryCardList,
                  setDisplayAddEventForm,
                  allowAddNewEvent
                }}
              />
            ),
            id: 1,
            title: 'Ongoing',
            tabsClass: 'float-left text-center mt-3 md:mt-0 w-1/2 md:w-auto'
          },
          {
            content: (
              <UserEventSummaryCardList
                {...{
                  userEventSummaryCardList: pastUserEventSummaryCardList,
                  setDisplayAddEventForm,
                  allowAddNewEvent,
                  pastUserEventSummaryCardList: true
                }}
              />
            ),
            id: 2,
            title: 'Past',
            tabsClass:
              'float-left text-center mt-3 md:mt-0 md:ml-10  w-1/2 md:w-auto'
          }
        ],
        tabsClass:
          'p-0 md:px-3 md:py-2 text-sm md:text-lg font-medium text-center inline'
      }}
    />
  </div>
);

export default UserEventNavigatorOrganism;
